define("discourse/plugins/discourse-uipath-mvp/discourse/initializers/user-profile-location", ["exports", "@ember/object", "discourse/lib/plugin-api"], function (_exports, _object, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "user-profile-location",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.24.0", api => {
        api.modifyClass("controller:preferences/profile", Superclass => class extends Superclass {
          // Temporarily disable custom location getter implementation in
          // `pre-initializers/user-location.js` before saving the user record.
          // This allows the custom location getter to return just the core location value
          // and not the custom `<Country Name>, <User Profile Location>`
          // implementation intended to be shown in the UI.
          save() {
            this.model.returnOriginalLocation = true;
            super.save(...arguments);
            this.model.returnOriginalLocation = false;
            this.model.notifyPropertyChange("location");
          }
          static #_ = (() => dt7948.n(this.prototype, "save", [_object.action]))();
        });
      });
    }
  };
});